import React from "react"
/* import { Link } from "gatsby" */

import Layout from "../components/layouts/layout"
/* import Image from "../components/image" */
import SEO from "../components/seo"

import Header4 from "../components/contents/header4"
/* import image from "loghi/Archimede.png"; */

import StoriaContent from "../content/storia/storia_content"
import StoriaContent2 from "../content/storia/storia_content2"
/* import ContentBgMod from "../components/htmls/contentBgMod" */
import ContentBg2 from "../components/htmls/contentBg2"
import BgFixed from "../components/htmls/BgFixed"
import WrapCol from "../components/htmls/wrapCol"

import { graphql } from "gatsby"

import { shuffle } from "../components/functions/index"

/* Variabili di pagina */
/* const s_img = "https://source.unsplash.com/b_HIKRj7EcY/1600x900" */
const colonne = 3
const larghezza_colonne = "26rem"
const larghezza_colonne2 = "28rem"
const link_page = "storia"

const StoriaPage = ({ data, location }) => {
  // const { state = {} } = location
  shuffle(data.allFile.edges)

  return (
    <Layout
      location={location}
      header={
        <Header4
          src_sfondo={data.allFile.edges[0].node.childImageSharp.fluid}
          title={data.site.siteMetadata.storia.title}
          link_page={data.site.siteMetadata.storia.link_page}
        />
      }
    >
      <SEO title={data.site.siteMetadata.storia.title} pathname={link_page} />

      <ContentBg2
        src_sfondo={data.placeholderImage.childImageSharp.fluid.src}
        color_text="black"
        backdropFilter="blur(6px)"
        trasparenza="rgba(255, 255, 255, 0.7)"
        id="s1"
      >
        <StoriaContent NCol={colonne} ColWidth={larghezza_colonne} />
      </ContentBg2>

      <BgFixed
        src_sfondo={data.placeholderImage2.childImageSharp.fluid.src}
        color_text="black"
        backdropFilter="blur(4px)"
        trasparenza="rgba(255, 255, 255, 0.5)"
      >
        <div
          style={{
            height: `15rem`,
          }}
        >
          <h2
            style={{
              paddingTop: `6rem`,
            }}
          >
            Mission
          </h2>
        </div>
      </BgFixed>

      <BgFixed backdropFilter="blur(4px)" trasparenza="rgba(255, 255, 255, 0)">
        <WrapCol MarginSpaceCol={1} NumCol={1} ColWidth={`300px`}>
          <div>
            <StoriaContent2
              NCol={1}
              alignText={"center"}
              ColWidth={larghezza_colonne2}
            />
          </div>
        </WrapCol>
      </BgFixed>
    </Layout>
  )
}

export const query = graphql`
  {
    allFile(
      limit: 10
      filter: { relativeDirectory: { eq: "storia/storia_carousel" } }
    ) {
      totalCount
      edges {
        node {
          id
          childImageSharp {
            fluid(quality: 70, maxWidth: 1200) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
    placeholderImage: file(relativePath: { eq: "storia/contA.jpg" }) {
      childImageSharp {
        fluid(quality: 50, maxWidth: 1024) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    placeholderImage2: file(relativePath: { eq: "storia/contB.jpg" }) {
      childImageSharp {
        fluid(quality: 60, maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        storia {
          title
          seo_title
          link_page
        }
      }
    }
  }
`

export default StoriaPage
