import React from "react"
import { Container, Col, Row /* , Image */ } from "react-bootstrap"

import PropTypes from "prop-types"
import Styles from "./contentBg.module.css"

const ContentDiv = ({
  src_sfondo,
  color_text,
  backdropFilter,
  trasparenza,
  children,
}) => {
  /* console.log(src_sfondo) */
  return (
    <>
      <div
        id={Styles.Background}
        style={{
          background: `url(${src_sfondo}) fixed center no-repeat`,
          backgroundSize: `cover`
        }}
      >
        <div
          id={Styles.DivTrasparent}
          className=""
          style={{
            backgroundColor: `${trasparenza}`,
            backdropFilter: `${backdropFilter}`,
          }}
        >
          <Container fluid className="">
            <Row className="align-items-center">
              <Col
                id={Styles.DivHeader}
                className="text-center"
                style={{
                  color: `${color_text}`,
                }}
              >
                {children}
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

ContentDiv.propTypes = {
  children: PropTypes.node.isRequired,
  src_sfondo: PropTypes.string,
  trasparenza: PropTypes.string,
}

ContentDiv.defaultProps = {
  src_sfondo: ``,
  trasparenza: `"rgba(0, 0, 0, 0.6)"`,
}

export default ContentDiv
