import scrollTo from "gatsby-plugin-smoothscroll"

export function scrollPage(is_scroll, path, millisecond = 1000) {
  /*     let { is_scroll } = state
    const { path } = state */

  if (is_scroll === false || is_scroll === undefined) {
    return
  }

  is_scroll = false

  setTimeout(function() {
    scrollTo(path)

    clearTimeout()
  }, millisecond)
}

export function shuffle(array) {
  var currentIndex = array.length,
    temporaryValue,
    randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex -= 1

    // And swap it with the current element.
    temporaryValue = array[currentIndex]
    array[currentIndex] = array[randomIndex]
    array[randomIndex] = temporaryValue
  }
  return array
}
