import React from "react"
import PropTypes from "prop-types"
import { /* Container, */ Col, Row /* , Card */ } from "react-bootstrap"
import MyStyles from "./wrapCol.module.css"

const WrapCol = ({
  children,
  title,
  body,
  MarginSpaceCol,
  NumCol,
  ColWidth,
  align_text,
}) => {
  /* let body_html = `dangerouslySetInnerHTML={{ __html: body }}` */
  return (
    <>
      <Row className="h-100 align-items-center">
        <Col>
          <Row>
            {/* xs={12} sm={12} md={10} lg={8} xl={6} */}
            <Col>
              <Row>
                <Col md={MarginSpaceCol}></Col>
                <Col className="text-center align-items-center">
                  {/* TITLE */}
                  <div className={MyStyles.text_block}>{title}</div>

                  {/* BODY */}
                  <div
                    className={`${MyStyles.columns} ${MyStyles.text_columns} ${MyStyles.paragraph}`}
                    style={{
                      columnCount: NumCol,
                      columnWidth: ColWidth,
                      textAlign: align_text,
                    }}
                    dangerouslySetInnerHTML={{ __html: body }}
                  />
                </Col>
                <Col md={MarginSpaceCol}></Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  )
}

WrapCol.propTypes = {
  body: PropTypes.string,
  /* title: PropTypes.string, */
}

export default WrapCol
