import React from "react"
import { StaticQuery, graphql } from "gatsby"

import WrapColInnerHtml from "../../components/htmls/WrapColInnerHtml"
/* import MyStyles from "./storia_content.module.css" */

const StoriaContent = ({ NCol, ColWidth }) => (
  <StaticQuery
    query={graphql`
      {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/storia_a/" } }
        ) {
          edges {
            node {
              excerpt(format: MARKDOWN)
              html
              frontmatter {
                date
                path
                title
              }
            }
          }
        }
      }
    `}
    render={data => (
      <>
        {data.allMarkdownRemark.edges.map(({ node }, index) => (
          
            <WrapColInnerHtml
              key={index}
              title={node.frontmatter.title}
              MarginSpaceCol={1}
              NumCol={NCol}
              ColWidth={ColWidth}
              body={node.html}
            />
        ))}
        {/* <div class="col-md-12 text-right">
							<img class="img-fluid w-50 mt-2" src="assets/images/loghi/firma.png" alt="">
						</div> */}
      </>
    )}
  />
)

export default StoriaContent